import React, { useState, useEffect, useReducer } from "react";
import { database } from "../../firebase";
import { ref, onValue, query, orderByChild, limitToFirst, remove, startAfter, onChildAdded, endBefore, onChildChanged, update, limitToLast, equalTo } from "firebase/database";
import { getFirestore, collection, query as queryFirestore, where, orderBy, limit, startAfter as startAfterF, getDocs, getCountFromServer, doc, deleteDoc, getDoc, updateDoc } from 'firebase/firestore';
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import Container from '@mui/material/Container';
import Table from '@mui/material/Table';
import Tooltip from '@mui/material/Tooltip';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import moment from "moment";
import SearchIcon from '@mui/icons-material/Search';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Pagination from '@mui/material/Pagination';
import CircularProgress from "@mui/material/CircularProgress";
import ModalFilters from "../../components/ModalFilters/ModalFilters";
import { StyledTableCell, StyledTableRow } from '../../utils/styled'
import { filesPage } from '../../utils/default'
import { cuadrillasFormat } from '../../utils/cuadrillas'
import DownloadExcel from "../../components/DonwloadExcel/DownloadExcel";
import { updateCuadrillaFirestore } from "../../services/cuadrillasService";
const firestore = getFirestore();

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const currentUser = JSON.parse(localStorage.getItem("currentUser"));


function ListOrdenes() {
    const [products, setProducts] = useState(null);
    const [productoFilter, setProductoFilter] = useState([]);
    const [searchTitle, setSearchTitle] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const [openServices, setOpenServices] = useState(false);
    const [openMaterials, setOpenMaterials] = useState(false);
    const [openConfigSerials, setOpenConfigSerials] = useState(false);
    const [openControls, setOpenControls] = useState(false);
    const [openSerials, setOpenSerials] = useState(false);
    const [detailsOrder, setDetailsOrder] = useState([]);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [ordenToDelete, setOrdenToDelete] = useState(-1);
    const [snackbarInfo, setSnackbarInfo] = useState({ text: '', type: '', open: false });
    const [pagePagination, setPagePagination] = useState(1);
    const [pageQuantity, setPageQuantity] = useState(1);
    const [isSearch, setIsSearch] = useState(false);
    const [openSearchModal, setOpenSearchModal] = useState(false);
    const [openDownloadModal, setOpenDownloadModal] = useState(false);
    const [numberPage, setNumberPage] = useState(false);
    const [spinnerDelete, setSpinnerDelete] = useState(false);

    const ordenesCollection = collection(firestore, 'ordenes');

    // Configuración del número de registros por página
    const recordsPerPage = 100;
    const INIT_ID = 48700

    // Obtener el número de página del query param
    const params = new URLSearchParams(window.location.search);
    const pageNumber = parseInt(params.get('page')) || 1;
    // setNumberPage(pageNumber)

    const calculateStartId = (page) => (page - 1) * recordsPerPage + INIT_ID;

    const getLastId = async () => {
        try {
            const collectionRef = collection(firestore, 'ordenes');

            const lastIdQuery = queryFirestore(collectionRef, orderBy('id', 'desc'), limit(1));
            const snapshot = await getDocs(lastIdQuery);

            if (!snapshot.empty) {
                const lastDoc = snapshot.docs[0].data();
                const totalCount = lastDoc.id;
                return totalCount;
            } else {
                return 0; // No hay documentos en la colección
            }
        } catch (error) {
            console.error("Error al obtener el último ID:", error);
            return 0;
        }
    };

    const fetchTotalCount = async () => {
        // Obtener la cuenta total de documentos
        // const snapshot = await getCountFromServer(countQuery);
        const lastIdPage = await getLastId();
        const totalCount = lastIdPage - INIT_ID;

        // Calcular el número total de páginas
        const pages = Math.ceil(totalCount / recordsPerPage);

        setPageQuantity(pages);
    };

    const fetchOrdenes = async (page) => {
        // Calcular el ID de inicio
        const startId = calculateStartId(page);

        // Preparamos la consulta base
        let ordenesQuery = queryFirestore(
            ordenesCollection,
            orderBy('id', 'asc'), // Orden ascendente por ID
            where('id', '>=', startId), // Filtrar desde el ID de inicio calculado
            limit(recordsPerPage) // Limitar al número de registros por página
        );

        // Aplicar filtros
        const cuadrilla = params.get('idCuadrilla');
        const nroOt = params.get('nroOt');
        const nroFormulario = params.get('nroFormulario');
        const contrato = params.get('contrato');
        const dateFrom = params.get('dateFrom');
        const dateTo = params.get('dateTo');

        // Agregar condiciones de filtro
        if (cuadrilla) {
            ordenesQuery = queryFirestore(ordenesCollection, orderBy('idCuadrilla', 'asc'), where('idCuadrilla', '==', parseInt(cuadrilla, 10)));
        }
        if (nroOt) {
            ordenesQuery = queryFirestore(ordenesCollection, orderBy('nroOt', 'asc'), where('nroOt', '==', nroOt));
        }
        if (nroFormulario) {
            ordenesQuery = queryFirestore(ordenesCollection, orderBy('nroFormulario', 'asc'), where('nroFormulario', '==', nroFormulario));
        }
        if (contrato) {
            ordenesQuery = queryFirestore(ordenesCollection, orderBy('contrato', 'asc'), where('contrato', '==', contrato));
        }
        if (dateFrom && dateTo) {
            ordenesQuery = queryFirestore(
                ordenesCollection, orderBy('date', 'asc'),
                where('date', '>=', dateFrom),
                where('date', '<=', dateTo)
            );
        }

        // Ejecutar la consulta
        const querySnapshot = await getDocs(ordenesQuery);

        if (!querySnapshot.empty) {
            const data = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
            setProducts(data);
            setIsSearch(true); // Se está realizando una búsqueda
        } else {
            setProducts([]);
            setIsSearch(false); // No se encontraron resultados
        }
    };

    useEffect(() => {
        if (!pageNumber & params.size === 0) {
            window.location.href = `?page=${1}`;
        }
        fetchTotalCount()
        fetchOrdenes(pageNumber);
    }, []);


    // useEffect(() => {
    //     const lastId = query(ref(database, 'ordenes'), orderByChild('id'), limitToLast(1));
    //     onValue(lastId, (snapshot) => {
    //         const key = snapshot.val() ? Object.keys(snapshot.val()) : 0;
    //         const id = snapshot.val()[key].id
    //         const pageQ = Math.ceil(parseInt(id, 10) / filesPage)
    //         setPageQuantity(pageQ)
    //     }, {
    //         onlyOnce: true
    //     });

    //     const params = new URLSearchParams(window.location.search);
    //     const searchParams = {};

    //     for (const [key, value] of params.entries()) {
    //         searchParams[key] = value;
    //     }

    // const pageNumber = params.get('page');
    // setNumberPage(pageNumber)
    // const keyParams = Object.keys(searchParams)
    // const dateFrom = params.get('dateFrom');
    // const dateTo = params.get('dateTo');
    // const hasDateFromOrDateTo = 'dateFrom' in searchParams && 'dateTo' in searchParams;
    // const paramFilter = keyParams.filter(param => param !== 'dateFrom' && param !== 'dateTo')[0];
    // if (params.size > 0 && !pageNumber) {
    //     const searchQuery = hasDateFromOrDateTo ?
    //         query(
    //             ref(database, 'ordenes'),
    //             orderByChild('date'),
    //             startAfter(dateFrom),
    //             endBefore(dateTo)
    //         ) :
    //         query(
    //             ref(database, 'ordenes'),
    //             orderByChild(paramFilter),
    //             equalTo(paramFilter === 'idCuadrilla' ? parseInt(searchParams[paramFilter], 10) : searchParams[paramFilter])
    //         )

    //     onValue(searchQuery, (snapshot) => {
    //         setIsSearch(true)
    //         let resultados = [];
    //         if (hasDateFromOrDateTo) {
    //             snapshot.forEach((childSnapshot) => {
    //                 const valor = childSnapshot.val()[paramFilter];
    //                 if (valor === searchParams[paramFilter]) {
    //                     resultados.push(childSnapshot.val());
    //                 }
    //             });
    //             setProducts(resultados)
    //         } else {
    //             resultados = snapshot.val() ? Object.values(snapshot.val()) : []
    //             setProducts(resultados)
    //         }
    //     }, {
    //         onlyOnce: true
    //     });
    // }

    //     setPagePagination(pageNumber)

    //     if (!pageNumber & params.size === 0) {
    //         window.location.href = `?page=${1}`;
    //     }

    //     const startFileId = (filesPage * (pageNumber - 1) - 1)

    //     const paginationQuery =
    //         query(ref(database, 'ordenes'),
    //             orderByChild('id'),
    //             startAfter(startFileId),
    //             limitToFirst(filesPage)
    //         );
    //     onValue(paginationQuery, (snapshot) => {
    //         setProducts(Object.values(snapshot.val()))
    //         // onChildAdded(topUserPostsRef, (snapshot) => {
    //         // ordersArray.push(snapshot.val())
    //         // setProducts(ordersArray)
    //     }, {
    //         onlyOnce: true
    //     });
    //     // const ordersArray = [];
    //     // const date = new Date();
    //     // const startDate = moment(date.setDate(date.getDate() - 16)).format("YYYYMMDD");
    //     // const topUserPostsRef = query(
    //     //     ref(database, 'ordenes'),
    //     //     orderByChild('date'),
    //     //     startAfter(startDate),
    //     //     limitToLast(100)
    //     // );
    //     // onChildAdded(topUserPostsRef, (snapshot) => {
    //     //     ordersArray.push(snapshot.val())
    //     //     setProducts(ordersArray)
    //     // }, {
    //     //     onlyOnce: true
    //     // });
    // }, []);
    //Consider adding ".indexOn": "id" at /ordenes to your security rules for better
    const handleDetails = (id) => {
        const filterInfo = products.filter(orden => parseInt(orden.id, 10) === parseInt(id, 10))
        setDetailsOrder(filterInfo[0]);
        setOpenModal(true);
    }

    const handleClose = () => {
        setOpenModal(false);
        setOpenDeleteModal(false);
        setOrdenToDelete(-1)
    };

    const handleCloseSanckbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarInfo({ text: '', type: 'info', open: false });
    };

    const getIdCuadrilla = (nameCuadrilla) => {
        const getCuadrillaFormat = cuadrillasFormat.filter(cuadrilla => cuadrilla?.numero?.split(' ')[0] === nameCuadrilla?.split(' ')[0])
        return getCuadrillaFormat[0]?.id;
    }

    const formatSerials = (serialOnt, serialFlow) => {
        const formatSerial = []
        serialOnt?.forEach(serial => {
            const dataFormat = {
                id: serial,
                tipo: 'ont'
            }
            formatSerial.push(dataFormat)
        })
        serialFlow?.forEach(serial => {
            const dataFormat = {
                id: serial,
                tipo: 'flow'
            }
            formatSerial.push(dataFormat)
        })
        return formatSerial
    }

    const deleteOnlyOrden = async () => {
        try {
            // Referencia al documento a eliminar en Firestore
            const docRef = doc(firestore, 'ordenes', String(ordenToDelete));

            // Eliminar el documento
            await deleteDoc(docRef);

            // Mostrar mensaje de éxito
            setSnackbarInfo({ text: 'Eliminado correctamente!', type: 'success', open: true });
            setOpenDeleteModal(false);
            setSpinnerDelete(false)

        } catch (error) {
            // Manejar el error
            console.log('error', error);

            setOpenDeleteModal(false);
            setSnackbarInfo({ text: 'Ocurrió un error', type: 'error', open: true });
            setSpinnerDelete(false)
        }
    };

    const fetchMaterialCuadrilla = async (idCuadrilla) => {
        let materialsCuadrilla = [];
        try {
            const materialsCuadrillaCollection = collection(firestore, 'materialsxcuadrilla');

            const cuadrillaQuery = query(
                materialsCuadrillaCollection,
                where('idCuadrilla', '==', parseInt(idCuadrilla, 10))
            );

            const querySnapshot = await getDocs(cuadrillaQuery);

            if (!querySnapshot.empty) {
                materialsCuadrilla = querySnapshot.docs.map(doc => doc.data());
            }
        } catch (error) {
            console.error('Error al obtener materialsxcuadrilla:', error);
        }
        return materialsCuadrilla;
    };

    const updateMaterialsCuadrilla = async (orderDelete, materialsCuadrilla, idCuadrilla) => {
        try {
            if (orderDelete[0]?.materials) {
                const materials = orderDelete[0]?.materials;

                for (const material of materials) {
                    // Filtrar el material correspondiente en materialsCuadrilla
                    const filterMaterialsByCuadrilla = materialsCuadrilla.filter(
                        cuadrilla => cuadrilla.idMaterial === parseInt(material.id, 10)
                    );

                    const currentQuantity = filterMaterialsByCuadrilla[0]?.cantidad || 0;
                    const quantityStock = currentQuantity + parseInt(material.value, 10);

                    // Referencia al documento en Firestore
                    const materialDocRef = doc(
                        firestore,
                        'materialsxcuadrilla',
                        `${idCuadrilla}c${material.id}`
                    );

                    // Verificar si el documento existe antes de intentar actualizarlo
                    const materialSnapshot = await getDoc(materialDocRef);
                    if (materialSnapshot.exists()) {
                        // Actualizar la cantidad
                        await updateDoc(materialDocRef, { cantidad: quantityStock });
                        console.log('ok update materialsxcuadrilla');
                    } else {
                        console.error(
                            `Material con ID ${idCuadrilla}c${material.id} no encontrado en Firestore`
                        );
                    }
                }
            }
        } catch (error) {
            console.error('Error al actualizar materialsxcuadrilla:', error);
            setSnackbarInfo({
                text: 'Ocurrió un error al actualizar los materiales',
                type: 'error',
                open: true,
            });
        }
    };

    const deleteOrden = async () => {
        setSpinnerDelete(true)
        const orderDelete = products.filter(orden => orden.id === ordenToDelete);
        const idCuadrilla = orderDelete[0]?.idCuadrilla ? orderDelete[0]?.idCuadrilla : getIdCuadrilla(orderDelete[0]?.cuadrilla)
        let materialsCuadrilla = [];
        let controlesCuadrilla = [];
        let serialsCuadrilla = [];
        let formatSerialsToSave = [];
        // const materCuadrilla = query(ref(database, 'materialsxcuadrilla'), orderByChild('idCuadrilla'), equalTo(parseInt(idCuadrilla, 10)));
        // onValue(materCuadrilla, (snapshot) => {
        //     const materialsCuadRec = snapshot.val() ? Object.values(snapshot.val()) : [];
        //     materialsCuadrilla = materialsCuadRec;
        // }, {
        //     onlyOnce: true
        // });
        fetchMaterialCuadrilla(idCuadrilla).then(materialsCuadrillaResult => {
            materialsCuadrilla = materialsCuadrillaResult;
        });
        const cuadrillasCollection = collection(firestore, 'cuadrillas');
        const queryCuadrillas = queryFirestore(cuadrillasCollection, where('id', '==', 3));;
        const snapshot = await getDocs(queryCuadrillas);
        const data = snapshot.docs.map(doc => doc.data());
        const serials = data[0]?.seriales || [];
        const controles = data[0]?.controles || [];
        serialsCuadrilla = serials
        controlesCuadrilla = controles
        // const queryCuadrilla = query(ref(database, 'cuadrillas'), orderByChild('id'), equalTo(parseInt(idCuadrilla, 10)));
        // onValue(queryCuadrilla, (snapshot) => {
        //     const serials = Object.values(snapshot.val())[0]?.seriales || [];
        //     const controles = Object.values(snapshot.val())[0]?.controles || [];
        //     serialsCuadrilla = serials
        //     controlesCuadrilla = controles
        // }, {
        //     onlyOnce: true
        // });
        setTimeout(() => {
            updateMaterialsCuadrilla(orderDelete, materialsCuadrilla, idCuadrilla);
            // if (orderDelete[0]?.materials) {
            //     orderDelete[0]?.materials.map(material => {
            //         const filterMaterialsByCuadrilla = materialsCuadrilla.filter(
            //             cuadrilla => cuadrilla.idMaterial === parseInt(material.id, 10));
            //         const quantityStock = filterMaterialsByCuadrilla[0]?.cantidad + parseInt(material.value, 10);
            //         return (
            //             update(ref(database, 'materialsxcuadrilla/' + idCuadrilla + 'c' + material.id),
            //                 { cantidad: quantityStock })
            //                 .then(() => {
            //                     console.log('ok update materialsxcuadrilla')
            //                 })
            //                 .catch((e) => {
            //                     setSnackbarInfo({ text: 'Ocurrió un error al actualizar los materiales', type: 'error', open: true })
            //                 })
            //         )
            //     })
            // }
            if (orderDelete[0]?.serialOnt || orderDelete[0]?.serialesFlow || orderDelete[0]?.controles) {
                const serialsToAdd = formatSerials(orderDelete[0]?.serialOnt, orderDelete[0]?.serialesFlow)
                formatSerialsToSave = serialsCuadrilla.concat(serialsToAdd)
                if (orderDelete[0]?.controles) {
                    orderDelete[0]?.controles.forEach(control => {
                        const filterTipoControl = controlesCuadrilla.filter(cont => cont.id === control.id)
                        let newControlSave = [];
                        const keys = Object.keys(controlesCuadrilla);
                        if (filterTipoControl.length > 0) {
                            let materialNewArray = [...controlesCuadrilla]
                            const keyService = keys.filter(key => controlesCuadrilla[key].id === control.id);
                            let materialToEdit = { ...materialNewArray[keyService] };
                            materialToEdit.value += control.value
                            materialNewArray[keyService] = materialToEdit
                            controlesCuadrilla = materialNewArray;
                        } else {
                            newControlSave = [...controlesCuadrilla, control]
                            controlesCuadrilla = newControlSave;
                        }
                    })
                }
                const dataToSaveCuadrilla = { seriales: formatSerialsToSave, controles: controlesCuadrilla }
                updateCuadrillaFirestore(idCuadrilla, dataToSaveCuadrilla)
                // update(ref(database, 'cuadrillas/' + idCuadrilla),
                //     { seriales: formatSerialsToSave, controles: controlesCuadrilla })
                //     .then(() => {
                //         console.log('ok cuadrillas receptora', formatSerialsToSave, controlesCuadrilla)
                //     })
                //     .catch((e) => {
                //         setSnackbarInfo({ text: 'Ocurrió un error al guardar los seriales', type: 'error', open: true })
                //     })
            }
            deleteOnlyOrden();
            // remove(ref(database, 'ordenes/' + ordenToDelete))
            //     .then(() => {
            //         setSnackbarInfo({ text: 'Eliminado correctamente!', type: 'success', open: true })
            //         setOpenDeleteModal(false);
            //         setSpinnerDelete(false)
            //         setTimeout(() => {
            //             window.location.reload();
            //         }, 2500);
            //     })
            //     .catch((error) => {
            //         setOpenDeleteModal(false)
            //         setSpinnerDelete(false)
            //         setSnackbarInfo({ text: 'Ocurrió un error', type: 'error', open: true })
            //     });
        }, 1000);

    }

    const displayTable = searchTitle !== "" ? productoFilter : products;

    const onChangePage = (number) => {
        window.location.href = `?page=${number}`;
    }

    const closeModalFilter = () => {
        setOpenSearchModal(false);
        setOpenDownloadModal(false);
    }

    const clearFilters = () => {
        const params = new URLSearchParams(window.location.search);
        const pageNumber = params.get('page') || 1;
        // const dateTo = params.get('date_to');
        // const dateFrom = params.get('date_from');
        window.location.href = `?page=${pageNumber}`
    }

    return (
        <Container component="main" maxWidth="xl">
            <Typography variant="h5">Listado de OT</Typography>
            <div className="filters-container">
                {/* <Paper
                    component="form"
                    sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400, marginBottom: '20px' }}
                >
                    <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Buscar"
                        inputProps={{ 'aria-label': 'buscar' }}
                        onChange={searchFilter}
                    />
                    <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                        <SearchIcon />
                    </IconButton> */}
                <Button
                    variant="contained"
                    endIcon={<SearchIcon />}
                    sx={{ marginBottom: '20px' }}
                    onClick={() => setOpenSearchModal(true)}
                >
                    Nueva Búsqueda
                </Button>
                <Button
                    variant="outlined"
                    // endIcon={<SearchIcon />}
                    sx={{ marginLeft: '20px', marginBottom: '20px' }}
                    onClick={() => clearFilters()}
                >
                    Limpiar Filtros
                </Button>

                {/* </Paper> */}
                <Button variant="contained" href="/ordenes" sx={{ marginBottom: '20px', marginLeft: 'auto' }}>Nueva OT</Button>
                {/* <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="download-table-xls-button"
                    table="table-to-xls"
                    filename="ordenes"
                    sheet="ordenes"
                    buttonText="Descargar planilla"
                /> */}
                <Button
                    variant="contained"
                    endIcon={<FileDownloadIcon />}
                    sx={{ marginLeft: '20px', marginBottom: '20px' }}
                    // onClick={handleExport}
                    onClick={() => setOpenDownloadModal(true)}
                >
                    Descargar planilla
                </Button>
            </div>

            <TableContainer>
                <Table aria-label="simple table" id="table-to-xls">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>ID</StyledTableCell>
                            <StyledTableCell>Fecha confección</StyledTableCell>
                            <StyledTableCell>Cuadrilla</StyledTableCell>
                            <StyledTableCell>Nro. Formulario</StyledTableCell>
                            <StyledTableCell>Nro. OT</StyledTableCell>
                            <StyledTableCell>Contrato</StyledTableCell>
                            <StyledTableCell>Cliente</StyledTableCell>
                            <StyledTableCell>Recibido Por</StyledTableCell>
                            <StyledTableCell>Dirección</StyledTableCell>
                            <StyledTableCell>Ciudad</StyledTableCell>
                            <StyledTableCell>Tipo Día</StyledTableCell>
                            <StyledTableCell>Tipo Cliente</StyledTableCell>
                            <StyledTableCell>Horas Trabajo</StyledTableCell>
                            <StyledTableCell>Fecha</StyledTableCell>
                            <StyledTableCell>Usuario</StyledTableCell>
                            <StyledTableCell>Acciones</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {displayTable?.length > 0 ? (
                            displayTable.map((producto, index) => (
                                <StyledTableRow key={index}>
                                    <StyledTableCell>{producto.id}</StyledTableCell>
                                    <StyledTableCell>{producto?.confectionData}</StyledTableCell>
                                    <StyledTableCell>{producto.cuadrilla}</StyledTableCell>
                                    <StyledTableCell>{producto.depto || producto.nroFormulario}</StyledTableCell>
                                    <StyledTableCell>{producto.codigo || producto.nroOt}</StyledTableCell>
                                    <StyledTableCell>{producto.contrato}</StyledTableCell>
                                    <StyledTableCell>{producto.cliente}</StyledTableCell>
                                    <StyledTableCell>{producto.recibido}</StyledTableCell>
                                    <StyledTableCell>{producto.direccion}</StyledTableCell>
                                    <StyledTableCell>{producto.ciudad}</StyledTableCell>
                                    <StyledTableCell>{producto.tipoDia}</StyledTableCell>
                                    <StyledTableCell>{producto.tipoCliente}</StyledTableCell>
                                    <StyledTableCell>{producto.horasTrabajo}</StyledTableCell>
                                    <StyledTableCell>{moment(producto.date, "YYYYMMDD").format("DD/MM/YYYY")}</StyledTableCell>
                                    <StyledTableCell>{producto?.user?.split('@')[0]}</StyledTableCell>

                                    <StyledTableCell className="column-actions" sx={{ display: 'inline-flex', border: 'none' }}>
                                        <Tooltip title="Ver detalle">
                                            <IconButton
                                                aria-label="see-more"
                                                className="action__link"
                                                onClick={(event) => {
                                                    event.preventDefault();
                                                    handleDetails(producto.id);
                                                }}
                                                role="button"
                                            >
                                                <VisibilityIcon />
                                            </IconButton>
                                        </Tooltip>
                                        {/* <IconButton
                                            aria-label="delete"
                                            className="action__link"
                                            // href={`/mp/products/${producto.id}`}
                                            role="button"
                                        >
                                            <EditIcon />
                                        </IconButton> */}
                                        <IconButton
                                            aria-label="delete"
                                            type="button"
                                            className="action__button"
                                            onClick={() => {
                                                setOpenDeleteModal(true);
                                                setOrdenToDelete(producto.id)
                                            }}
                                        >
                                            <DeleteIcon sx={{ color: 'red' }} />
                                        </IconButton>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))
                        ) : (
                            <StyledTableRow>
                                <StyledTableCell colSpan={16} align="center">
                                    {displayTable?.length === 0 ? (
                                        <Typography variant="h5">
                                            No se encontraron resultados
                                        </Typography>
                                    ) : (
                                        <CircularProgress />
                                    )}
                                </StyledTableCell>
                            </StyledTableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            {displayTable?.length > 0 && pageNumber &&
                <Pagination
                    count={pageQuantity}
                    page={parseInt(pageNumber, 10)}
                    className="list-pagination"
                    onChange={(e, number) => onChangePage(number)}
                    size="large"
                    sx={{ margin: '15px 0', justifyContent: 'center' }}
                />
            }
            <Dialog open={openModal} onClose={handleClose} >
                <DialogTitle>Detalles</DialogTitle>
                <DialogContent sx={{ minWidth: '500px' }}>
                    <List
                        sx={{ width: '100%', bgcolor: 'background.paper' }}
                        component="nav"
                    >
                        <ListItemButton onClick={() => setOpenServices(!openServices)}>
                            <ListItemText primary="Servicios" />
                            {openServices ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={openServices} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {detailsOrder && detailsOrder?.services?.map(service => {
                                    const nameService = typeof service.value === 'boolean' ? service.name : `${service.name}: ${service.value}`;
                                    return (
                                        <ListItem sx={{ pl: 4 }}>
                                            <ListItemText primary={`- ${nameService}`} />
                                        </ListItem>
                                    )
                                })}
                            </List>
                        </Collapse>
                    </List>
                    <List
                        sx={{ width: '100%', bgcolor: 'background.paper' }}
                        component="nav"
                    >
                        <ListItemButton onClick={() => setOpenMaterials(!openMaterials)}>
                            <ListItemText primary="Materiales" />
                            {openMaterials ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={openMaterials} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {detailsOrder && detailsOrder?.materials?.map(material => (
                                    <ListItem sx={{ pl: 4 }}>
                                        <ListItemText primary={`- ${material.name} : ${material.value}`} />
                                    </ListItem>
                                )
                                )}
                            </List>
                        </Collapse>
                    </List>
                    <List
                        sx={{ width: '100%', bgcolor: 'background.paper' }}
                        component="nav"
                    >
                        <ListItemButton onClick={() => setOpenConfigSerials(!openConfigSerials)}>
                            <ListItemText primary="Seriales Configurados" />
                            {openConfigSerials ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={openConfigSerials} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {detailsOrder?.serialesConfigurados?.length > 0 && detailsOrder?.serialesConfigurados?.map(serial => (
                                    <ListItem sx={{ pl: 4 }}>
                                        <ListItemText primary={serial} />
                                    </ListItem>
                                )
                                )}
                            </List>
                        </Collapse>
                    </List>
                    {detailsOrder?.controles &&
                        <List
                            sx={{ width: '100%', bgcolor: 'background.paper' }}
                            component="nav"
                        >
                            <ListItemButton onClick={() => setOpenControls(!openControls)}>
                                <ListItemText primary="Controles" />
                                {openControls ? <ExpandLess /> : <ExpandMore />}
                            </ListItemButton>
                            <Collapse in={openControls} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    {detailsOrder?.controles.map(control => (
                                        <ListItem sx={{ pl: 4 }}>
                                            <ListItemText primary={`${control.name.toUpperCase()} : ${control.value}`} />
                                        </ListItem>
                                    ))}
                                </List>
                            </Collapse>
                        </List>
                    }
                    <List
                        sx={{ width: '100%', bgcolor: 'background.paper' }}
                        component="nav"
                    >
                        <ListItemButton onClick={() => setOpenSerials(!openSerials)}>
                            <ListItemText primary="Seriales" />
                            {openSerials ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={openSerials} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListSubheader component="div" id="nested-list-subheader">
                                    Seriales Retirados
                                </ListSubheader>
                                <ListItem sx={{ pl: 4 }}>
                                    {detailsOrder?.ontRetirado ?
                                        <ListItemText primary={`ONT: ${detailsOrder?.ontRetirado?.length > 1 ? detailsOrder?.ontRetirado.join(', ') : detailsOrder?.ontRetirado}`} />
                                        :
                                        <ListItemText primary="ONT: -" />
                                    }
                                </ListItem>
                                <ListItem sx={{ pl: 4 }}>
                                    {detailsOrder?.decoRetirado ?
                                        <ListItemText primary={`FLOW: ${detailsOrder?.decoRetirado?.length > 1 ? detailsOrder?.decoRetirado.join(', ') : detailsOrder?.decoRetirado}`} />
                                        :
                                        <ListItemText primary="FLOW: -" />
                                    }
                                </ListItem>
                                <ListSubheader component="div" id="nested-list-subheader">
                                    Seriales Usados
                                </ListSubheader>
                                <ListItem sx={{ pl: 4 }}>
                                    {detailsOrder?.serialOnt ?
                                        <ListItemText primary={`ONT: ${detailsOrder?.serialOnt?.length > 1 ? detailsOrder?.serialOnt.join(', ') : detailsOrder?.serialOnt}`} />
                                        :
                                        <ListItemText primary="ONT: -" />
                                    }
                                </ListItem>
                                <ListItem sx={{ pl: 4 }}>
                                    {detailsOrder?.serialesFlow ?
                                        <ListItemText primary={`FLOW: ${detailsOrder?.serialesFlow?.length > 1 ? detailsOrder?.serialesFlow.join(', ') : detailsOrder?.serialesFlow}`} />
                                        :
                                        <ListItemText primary="FLOW: -" />
                                    }
                                </ListItem>
                                <ListSubheader component="div" id="nested-list-subheader">
                                    Seriales Instalados Bodega
                                </ListSubheader>
                                <ListItem sx={{ pl: 4 }}>
                                    {detailsOrder?.ontBodega ?
                                        <ListItemText primary={`ONT: ${detailsOrder?.ontBodega?.length > 1 ? detailsOrder?.ontBodega.join(', ') : detailsOrder?.ontBodega}`} />
                                        :
                                        <ListItemText primary="ONT: -" />
                                    }
                                </ListItem>
                                <ListItem sx={{ pl: 4 }}>
                                    {detailsOrder?.flowBodega ?
                                        <ListItemText primary={`FLOW: ${detailsOrder?.flowBodega?.length > 1 ? detailsOrder?.flowBodega.join(', ') : detailsOrder?.flowBodega}`} />
                                        :
                                        <ListItemText primary="FLOW: -" />
                                    }
                                </ListItem>
                            </List>
                        </Collapse>
                    </List>
                    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                        <ListItem alignItems="flex-start">
                            <ListItemText
                                primary="Comentarios"
                                secondary={detailsOrder?.comments}
                            />
                        </ListItem>
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cerrar</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openDeleteModal}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Desea eliminar esta órden?"}
                </DialogTitle>
                <DialogContent>
                    {spinnerDelete ? (
                        <CircularProgress />
                    ) : (
                        <DialogContentText id="alert-dialog-description">
                            Los cambios serán permanentes.
                        </DialogContentText>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cerrar</Button>
                    <Button onClick={deleteOrden} autoFocus>
                        Aceptar
                    </Button>
                </DialogActions>
            </Dialog>
            {openSearchModal &&
                <ModalFilters
                    open={openSearchModal}
                    onCloseModal={() => setOpenSearchModal(false)}
                    items={['cuadrilla', 'contrato', 'nroOt', 'nroFormulario', 'dateTo', 'dateFrom']}
                />
            }
            {openDownloadModal && <DownloadExcel open={openDownloadModal} onCloseModal={closeModalFilter} />}
            <Snackbar open={snackbarInfo.open} autoHideDuration={2000} onClose={handleCloseSanckbar} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert onClose={handleCloseSanckbar} severity={snackbarInfo.type} sx={{ width: '100%' }}>
                    {snackbarInfo.text}
                </Alert>
            </Snackbar>
        </Container>
    );
}

export default ListOrdenes;

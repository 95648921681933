import React, { useState, useEffect } from "react";
import { database } from "../../firebase";
import { ref, onValue, update, set } from "firebase/database";
import { getFirestore, collection, getDocs, doc, updateDoc, setDoc } from 'firebase/firestore';
import { styled } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Snackbar from '@mui/material/Snackbar';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import ButtonGroup from '@mui/material/ButtonGroup';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import { StyledTableCell, StyledTableRow } from '../../utils/styled'
import { idCuadrillaNucleo } from '../../utils/default'
import MuiAlert from '@mui/material/Alert';
import { getCuadrillasOnce } from "../../services/cuadrillasService";
import { getMaterialesOnce } from "../../services/materialsService";

const firestore = getFirestore();

const currentUser = JSON.parse(localStorage.getItem("currentUser"));

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function StockList() {
    const [quantityMaterials, setQuantityMaterials] = useState([])
    const [materials, setMaterials] = useState([])
    const [cuadrilla, setCuadrilla] = useState([])
    const [indexOpen, setIndexOpen] = useState([-1, -1])
    const [stockQuantity, setStockQuantity] = useState(0)
    const [openSnackbar, setOpenSnackbar] = useState({ open: false, message: '', type: 'success' })
    const [selectMaterials, setSelectMaterials] = useState(true)

    const fetchMaterialsCuadrilla = async () => {
        try {
            const materialsCollection = collection(firestore, 'materialsxcuadrilla');
            const querySnapshot = await getDocs(materialsCollection);

            // Transformar los documentos en un array de objetos
            const materialsCuad = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));

            // Llamar a la función de cambio de datos
            setQuantityMaterials(materialsCuad);
        } catch (error) {
            console.error('Error al obtener los datos de materialsxcuadrilla:', error);
        }
    };

    useEffect(() => {
        // onValue(ref(database, '/materialsxcuadrilla/'), (snapshot) => {
        //     const materialsCuad = snapshot.val() ? Object.values(snapshot.val()) : [];
        //     onDataChange(materialsCuad)
        // }, {
        //     onlyOnce: true
        // });
        // onValue(ref(database, '/cuadrillas/'), (snapshot) => {
        //     setCuadrilla(Object.values(snapshot.val()))
        // }, {
        //     onlyOnce: true
        // });
        fetchMaterialsCuadrilla();
        getMaterialesOnce(setMaterials)
        getCuadrillasOnce(setCuadrilla)
    }, []);


    const handleOpenModal = (indexMaterials, indexCuadrillas, cantidad) => {
        const indexOpenModal = [indexMaterials, indexCuadrillas];
        setIndexOpen(indexOpenModal);
        setStockQuantity(cantidad);
    }

    const handleClose = () => {
        setIndexOpen([-1, -1]);
    }

    const onChangeValueCant = (e) => {
        const value = e.target.value;
        setStockQuantity(value);
    }

    const editStock = async (indexMaterials, indexCuadrillas, type) => {
        try {
            // Filtrar cuadrilla (esto parece ser solo para depuración en el log)
            const filterCuadrilla = quantityMaterials.filter(
                (c) => parseInt(c.idCuadrilla, 10) === indexCuadrillas &&
                    parseInt(c.idMaterial, 10) === indexMaterials
            );
            console.log('indexlcac', Object.keys(filterCuadrilla));

            const docRef = doc(firestore, 'materialsxcuadrilla', `${indexCuadrillas}c${indexMaterials}`);

            if (type === 'edit') {
                // Editar documento existente
                await updateDoc(docRef, { cantidad: parseInt(stockQuantity, 10) });
                setIndexOpen([-1, -1]);
                setOpenSnackbar({ open: true, message: 'Cantidad editada correctamente', type: 'success' });
            } else {
                // Crear un nuevo documento
                const dataToStock = {
                    cantidad: parseInt(stockQuantity, 10),
                    idCuadrilla: indexCuadrillas,
                    idMaterial: indexMaterials
                };
                await setDoc(docRef, dataToStock);
                setIndexOpen([-1, -1]);
            }
        } catch (e) {
            console.error('Error al editar o crear el stock:', e);
        }
    };

    const handleCloseSanckbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnackbar({ open: false, message: '', type: 'success' });
    };


    return (
        <Container component="main" maxWidth="xl">
            <h4>Stock</h4>
            <ButtonGroup variant="outlined" aria-label="outlined button group" sx={{ marginBottom: '20px' }}>
                <Button
                    variant={selectMaterials ? 'contained' : 'outlined'}
                    onClick={() => setSelectMaterials(!selectMaterials)}
                >
                    Materiales
                </Button>
                <Button
                    variant={!selectMaterials ? 'contained' : 'outlined'}
                    onClick={() => setSelectMaterials(!selectMaterials)}
                >
                    Equipos
                </Button>
            </ButtonGroup>
            {selectMaterials ? (
                <TableContainer sx={{ maxHeight: 840 }}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell
                                    sx={{
                                        position: "sticky",
                                        background: "black",
                                        zIndex: 4,
                                        left: 0,
                                    }}
                                >
                                </StyledTableCell>
                                {cuadrilla?.map((cuadr, index) => (
                                    // cuadr.id !== idCuadrillaNucleo &&
                                    <StyledTableCell key={cuadr.id}>{cuadr.numero}</StyledTableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {materials.map((value, indexMaterials) => (
                                value?.visible &&
                                <StyledTableRow key={indexMaterials}>
                                    <StyledTableCell
                                        sx={{
                                            position: "sticky",
                                            zIndex: 4,
                                            left: 0,
                                            background: "#efecec",
                                        }}
                                    >
                                        {value?.name}
                                    </StyledTableCell>
                                    {cuadrilla?.map((cuadrillas, indexCuadrillas) => {
                                        const filterCuadrilla = quantityMaterials.filter(
                                            (c, i) => value.visible && parseInt(c.idCuadrilla, 10) === cuadrillas.id &&
                                                parseInt(c.idMaterial, 10) === value.id)[0];
                                        return (
                                            filterCuadrilla ?
                                                <StyledTableCell key={indexCuadrillas} sx={{ padding: '7px 7px 7px 27px' }}>
                                                    {filterCuadrilla.cantidad}
                                                    {currentUser?.userName === 'estebanpalacios' || currentUser?.rol === 'admin' &&
                                                        <IconButton
                                                            className="action__link"
                                                            size="small"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                handleOpenModal(indexMaterials, indexCuadrillas, filterCuadrilla.cantidad);
                                                            }}
                                                        >
                                                            <EditIcon />
                                                        </IconButton>
                                                    }
                                                    <Dialog
                                                        open={indexOpen[0] === indexMaterials && indexOpen[1] === indexCuadrillas}
                                                        aria-labelledby="form-dialog-title"
                                                    >
                                                        <DialogTitle
                                                            id="form-dialog-title"
                                                            onClose={handleClose}
                                                        >
                                                            Editar stock
                                                        </DialogTitle>
                                                        <DialogContent>
                                                            <TextField
                                                                className="prod-input"
                                                                autoFocus
                                                                fullWidth
                                                                margin="dense"
                                                                name="cantidad"
                                                                label="Cantidad"
                                                                type="number"
                                                                value={stockQuantity}
                                                                onChange={onChangeValueCant}
                                                            />
                                                        </DialogContent>
                                                        <DialogActions>
                                                            <Button color="primary" onClick={handleClose}>
                                                                Cancelar
                                                            </Button>
                                                            <Button
                                                                color="primary"
                                                                disabled={stockQuantity === ''}
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    editStock(indexMaterials, indexCuadrillas, 'edit');
                                                                }}
                                                            >
                                                                Aceptar
                                                            </Button>
                                                        </DialogActions>
                                                    </Dialog>
                                                </StyledTableCell>
                                                :
                                                <StyledTableCell key={indexCuadrillas} sx={{ padding: '7px 7px 7px 27px' }}>
                                                    0
                                                    <Dialog
                                                        open={indexOpen[0] === indexMaterials && indexOpen[1] === indexCuadrillas}
                                                        aria-labelledby="form-dialog-title"
                                                    >
                                                        <DialogTitle
                                                            id="form-dialog-title"
                                                            onClose={handleClose}
                                                        >
                                                            Editar stock
                                                        </DialogTitle>
                                                        <DialogContent>
                                                            <TextField
                                                                className="prod-input"
                                                                autoFocus
                                                                fullWidth
                                                                margin="dense"
                                                                name="cantidad"
                                                                label="Cantidad"
                                                                type="number"
                                                                value={stockQuantity}
                                                                onChange={onChangeValueCant}
                                                            />
                                                        </DialogContent>
                                                        <DialogActions>
                                                            <Button color="primary" onClick={handleClose}>
                                                                Cancelar
                                                            </Button>
                                                            <Button
                                                                color="primary"
                                                                disabled={stockQuantity === ''}
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    editStock(indexMaterials, indexCuadrillas, 'save');
                                                                }}
                                                            >
                                                                Aceptar
                                                            </Button>
                                                        </DialogActions>
                                                    </Dialog>
                                                </StyledTableCell>
                                        )
                                    })}
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <TableContainer>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Nombre</StyledTableCell>
                                <StyledTableCell align="center">Seriales</StyledTableCell>
                                <StyledTableCell>Controles</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {cuadrilla?.map((cuadrilla, index) => {
                                console.log('cuadrilla', cuadrilla?.seriales);

                                const serialFlowFilter = cuadrilla?.seriales?.filter(serial => serial.tipo === 'flow')
                                const serialOntFilter = cuadrilla?.seriales?.filter(serial => serial.tipo === 'ont')
                                const serialExtFilter = cuadrilla?.seriales?.filter(serial => serial.tipo === 'ext')
                                return (
                                    (cuadrilla.id !== idCuadrillaNucleo) &&
                                    <StyledTableRow
                                        key={index}
                                    >
                                        <StyledTableCell>{cuadrilla.numero}</StyledTableCell>
                                        <StyledTableCell>
                                            {cuadrilla?.seriales &&
                                                <div className="serial-container">
                                                    <div className="serial-type">
                                                        <span className="title-serial">ONT</span>
                                                        <div>
                                                            {serialOntFilter?.map(serial => (
                                                                <p className="value-serial">{serial.id}</p>
                                                            ))}
                                                        </div>
                                                    </div>
                                                    <div className="serial-type">
                                                        <span className="title-serial">FLOW</span>
                                                        <div>
                                                            {serialFlowFilter?.map(serial => (
                                                                <p className="value-serial">{serial.id}</p>
                                                            ))}
                                                        </div>
                                                    </div>
                                                    <div className="serial-type">
                                                        <span className="title-serial">EXT</span>
                                                        <div>
                                                            {serialExtFilter?.map(serial => (
                                                                <p className="value-serial">{serial.id}</p>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </StyledTableCell>
                                        <StyledTableCell>{cuadrilla?.controles?.map(control => (
                                            <div>
                                                <span>{control.name.toUpperCase()} : </span>
                                                <span> {control.value}</span>
                                            </div>
                                        ))
                                        }
                                        </StyledTableCell>
                                    </StyledTableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}

            <Snackbar open={openSnackbar.open} autoHideDuration={2000} onClose={handleCloseSanckbar} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert onClose={handleCloseSanckbar} severity={openSnackbar.type} sx={{ width: '100%' }}>
                    {openSnackbar.message}
                </Alert>
            </Snackbar>
        </Container>
    );
}

export default StockList;

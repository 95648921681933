import React, { useState, useEffect } from "react";
import { database } from "../../firebase";
import { ref, onValue, onChildAdded, query, orderByChild, startAfter, limitToLast, orderByValue, orderByKey, endAt, get } from "firebase/database";
import { getFirestore, collection, query as queryFirestore, where, orderBy, limit, startAfter as startAfterF, getDocs, getCountFromServer } from 'firebase/firestore';
import Container from '@mui/material/Container';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import moment from "moment";
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { StyledTableCell, StyledTableRow } from '../../utils/styled'
import { Button, CircularProgress } from "@mui/material";

const firestore = getFirestore();

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function SerialesSearch() {
    const [remitos, setRemitos] = useState([]);
    const [ordenes, setOrdenes] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [searchResult, setSearchResult] = useState([]);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [snackbarInfo, setSnackbarInfo] = useState({ text: '', type: '', open: false });
    const [precioFilter, setPrecioFilter] = useState([]);
    const [searchTitle, setSearchTitle] = useState('');
    const [loading, setLoading] = useState(false)

    const exUseEffect = () => {
        const date = new Date();
        const startDate = moment(date.setDate(date.getDate() - 90)).format("YYYYMMDD");
        onValue(ref(database, '/remitos/'), (snapshot) => {
            setRemitos(Object.values(snapshot.val()))
        }, {
            onlyOnce: true
        });
        // const searchQuery = query(ref(database, 'ordenes'), orderByChild('nroFormulario'), equalTo(nroFormulario));

        // onValue(searchQuery, (snapshot) => {
        //     const resultOrder = Object.values(snapshot.val()) || []
        //     setOrdenes(resultOrder)
        // }, {
        //     onlyOnce: true
        // });

        const orderQuery = query(
            ref(database, 'ordenes'),
            orderByChild('date'),
            startAfter(startDate),
        );
        onValue(orderQuery, (snapshot) => {
            const resultOrder = Object.values(snapshot.val()) || []
            setOrdenes(resultOrder)
        }, {
            onlyOnce: true
        });
        // onChildAdded(topUserPostsRef, (snapshot) => {
        //     console.log('snapshot.val()', snapshot.val());
        //     // ordersArray.push(snapshot.val())
        //     // setOrdenes(ordersArray)
        // }, {
        //     onlyOnce: true
        // });
        // const cuadrillaQuery = query(ref(database, 'ordenes2'), orderByChild('fecha'));
        // onValue(cuadrillaQuery, (snapshot) => {
        //     console.log('fech', snapshot.val());
        // }, {
        //     onlyOnce: true
        // });
    };
    useEffect(() => {
        getInfo()
    }, []);

    const getInfo1 = async () => {
        setLoading(true)
        // const date = new Date();
        // const startDate = moment(date.setDate(date.getDate() - 90)).format("YYYYMMDD");

        // // Obtener ordenes
        // const ordenesCollection = collection(firestore, 'ordenes');
        // let ordenesQuery = query(
        //     ordenesCollection,
        //     orderBy('id', 'asc'), // Orden ascendente por ID
        // );
        // const queryOrderSnapshot = await getDocs(ordenesQuery);
        // if (!queryOrderSnapshot.empty) {
        //     const data = queryOrderSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
        //     setOrdenes(data);
        //     setLoading(false);
        // }

        // Obtener remitos
        const remitosCollection = collection(firestore, 'remitos');
        let remitosQuery = query(
            remitosCollection,
            orderBy('id', 'asc'), // Orden ascendente por ID
        );
        const querySnapshot = await getDocs(remitosQuery);
        if (!querySnapshot.empty) {
            const data = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
            setRemitos(data);
            setLoading(false);
        }
        // const querySnapshot = await getDocs(remitosQuery);
        // const remitosSnapshot = await get(ref(database, '/remitos/'));
        // const remitosData = remitosSnapshot.exists() ? Object.values(remitosSnapshot.val()) : [];
        // setRemitos(remitosData);
        // setLoading(false);
    }

    const getInfo = async () => {
        try {
            const remitosCollection = collection(firestore, 'remitos');
            let remitosQuery = query(
                remitosCollection,
                orderBy('id', 'asc'), // Orden ascendente por ID
            );

            const querySnapshot = await getDocs(remitosQuery);
            if (!querySnapshot.empty) {
                const data = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
                setRemitos(data); // Establecer los remitos en el estado
            }
        } catch (error) {
            console.error("Error al obtener los remitos: ", error);
        }
    };

    const handleClose = () => {
        setOpenDeleteModal(false)
    }

    const handleCloseSanckbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        // setSnackbarInfo({ text: '', type: '', open: false });
    };

    const searchFilter = async () => {
        setLoading(true);
        // await getInfo()
        const newResult = []

        remitos.forEach(remito => {
            const serialFilter = remito.serials?.filter(serial => serial?.id?.toString() === searchValue)

            if (serialFilter?.length > 0) {

                const formatResult = {
                    id: remito.id,
                    fecha: remito.fecha,
                    cuadrillaEntrega: remito.cuadrillaEntrega,
                    cuadrillaReceptora: remito.cuadrillaReceptora,
                    tipoRemito: remito.tipoRemito,
                    type: 'remito',
                    serial: searchValue
                }

                newResult.push(formatResult)
            }
        })
        console.log('newResult', newResult);

        await searchFilterOrders(newResult);

        // Actualiza el estado con los resultados combinados
        // setSearchResult(newResult);
        setLoading(false);

        // ordenes.forEach(orden => {
        //     const orderFilterOnt = Array.isArray(orden?.serialOnt) && orden?.serialOnt?.filter(serial => serial === searchValue)
        //     const orderFilterFlow = Array.isArray(orden?.serialesFlow) && orden?.serialesFlow?.filter(serial => serial === searchValue)
        //     const orderFilterOntR = Array.isArray(orden?.ontRetirado) && orden?.ontRetirado?.filter(serial => serial === searchValue)
        //     const orderFilterFlowR = Array.isArray(orden?.decoRetirado) && orden?.decoRetirado?.filter(serial => serial === searchValue)
        //     if (orderFilterOnt?.length > 0 || orderFilterFlow?.length > 0 || orderFilterOntR?.length > 0 || orderFilterFlowR?.length > 0) {
        //         const formatResult = {
        //             id: orden.nroOt,
        //             fecha: moment(orden.date).format("DD/MM/YYYY"),
        //             cuadrillaEntrega: orden.cuadrilla,
        //             cuadrillaReceptora: '-',
        //             tipoRemito: orden.tipoOt,
        //             type: 'orden',
        //             serial: searchValue
        //         }
        //         newResult.push(formatResult)
        //     }
        //     setSearchResult(newResult)
        // })
    }

    const searchFilterOrders = async (newResults) => {
        let combinedResults = newResults;

        try {
            const firestore = getFirestore(); // Asegúrate de inicializar Firestore correctamente

            // Colecciones
            const remitosCollection = collection(firestore, "remitos");
            const ordenesCollection = collection(firestore, "ordenes");

            // Consultas específicas
            // const remitosQuery = queryFirestore(remitosCollection, where("serials", "array-contains", searchValue));
            const ordenesQueryOnt = queryFirestore(ordenesCollection, where("serialOnt", "array-contains", searchValue));
            const ordenesQueryFlow = queryFirestore(ordenesCollection, where("serialesFlow", "array-contains", searchValue));
            const ordenesQueryOntR = queryFirestore(ordenesCollection, where("ontRetirado", "array-contains", searchValue));
            const ordenesQueryFlowR = queryFirestore(ordenesCollection, where("decoRetirado", "array-contains", searchValue));

            // Ejecuta todas las consultas en paralelo
            const [ordenesOntSnapshot, ordenesFlowSnapshot, ordenesOntRSnapshot, ordenesFlowRSnapshot] = await Promise.all([
                // getDocs(remitosQuery),
                getDocs(ordenesQueryOnt),
                getDocs(ordenesQueryFlow),
                getDocs(ordenesQueryOntR),
                getDocs(ordenesQueryFlowR),
            ]);
            // console.log('remitosSnapshot', remitosSnapshot);

            // Procesa los resultados
            // const remitosResult = remitosSnapshot.docs.map((doc) => ({
            //     id: doc.id,
            //     type: "remito",
            //     serial: searchValue,
            //     fecha: doc.fecha,
            //     cuadrillaEntrega: doc.cuadrillaEntrega,
            //     cuadrillaReceptora: doc.cuadrillaReceptora,
            //     tipoRemito: doc.tipoRemito,
            // }));

            const ordenesResult = [
                ...ordenesOntSnapshot.docs,
                ...ordenesFlowSnapshot.docs,
                ...ordenesOntRSnapshot.docs,
                ...ordenesFlowRSnapshot.docs,
            ].map((doc) => ({
                id: doc.data().nroOt,
                type: 'orden',
                serial: searchValue,
                fecha: moment(doc.data().date).format("DD/MM/YYYY"),
                cuadrillaEntrega: doc.data().cuadrilla,
                cuadrillaReceptora: '-',
                tipoRemito: doc.data().tipoOt,
            })
            );

            combinedResults = combinedResults.concat(ordenesResult)

        } catch (error) {
            console.error("Error en la búsqueda:", error);
        }
        setSearchResult(combinedResults);
    };

    const onChangeSearch = (e) => {
        setSearchValue(e.target.value)
    }

    const onKeyPress = (e) => {
        if (e.keyCode === 13) {
            searchFilter();
        }
    }


    const displayTable = searchTitle !== "" ? precioFilter : searchResult;

    return (
        <Container component="main" maxWidth="xl">
            <Typography variant="h5">Buscador de Seriales</Typography>
            <div className="filters-container">
                <Paper
                    sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400, marginBottom: '20px' }}
                >
                    <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Buscar serial"
                        inputProps={{ 'aria-label': 'buscar' }}
                        onKeyDown={onKeyPress}
                        onChange={onChangeSearch}
                    />
                    <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={searchFilter}>
                        <SearchIcon />
                    </IconButton>
                </Paper>
                <MuiAlert severity="info" sx={{ marginLeft: '20px', height: '36px' }}>
                    Realizar la búsqueda 2 veces para traer todos los resultados
                </MuiAlert>
            </div>
            <TableContainer>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>ID/Nº OT</StyledTableCell>
                            <StyledTableCell>Fecha</StyledTableCell>
                            <StyledTableCell>Nº Serial</StyledTableCell>
                            <StyledTableCell>Tipo</StyledTableCell>
                            <StyledTableCell>Almacén origen</StyledTableCell>
                            <StyledTableCell>Almacén destino</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {searchResult.length > 0 ? (
                            displayTable.map((remito, index) => {
                                const linkSearch = remito.type === 'orden' ? `/list-ordenes?nroOt=${remito.id}` :
                                    `/remito-list?idRemito=${remito.id}`
                                return (
                                    <StyledTableRow
                                        key={index}
                                        sx={remitos?.deleted && { display: 'none' }}
                                    >
                                        <StyledTableCell>
                                            <Button variant="text" href={linkSearch}>{remito.id}</Button>
                                        </StyledTableCell>
                                        <StyledTableCell>{remito.fecha}</StyledTableCell>
                                        <StyledTableCell>{remito.serial}</StyledTableCell>
                                        <StyledTableCell>{remito.tipoRemito?.toUpperCase()}</StyledTableCell>
                                        <StyledTableCell>{remito.cuadrillaEntrega}</StyledTableCell>
                                        <StyledTableCell>{remito.cuadrillaReceptora}</StyledTableCell>
                                    </StyledTableRow>
                                )
                            })
                        ) : (
                            <StyledTableRow>
                                <StyledTableCell colSpan={13} align="center">
                                    {loading ? (
                                        <CircularProgress />

                                    ) : (
                                        <Typography variant="h5">
                                            No se encontraron elementos
                                        </Typography>

                                    )}
                                </StyledTableCell>
                            </StyledTableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <Snackbar open={snackbarInfo.open} autoHideDuration={2000} onClose={handleCloseSanckbar} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert onClose={handleClose} severity={snackbarInfo.type} sx={{ width: '100%' }}>
                    {snackbarInfo.text}
                </Alert>
            </Snackbar>
        </Container>
    );
}

export default SerialesSearch;

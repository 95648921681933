import React, { useState, useEffect } from "react";
import AddMaterials from "../Materials/AddMaterials";
import AddServices from "../Services/AddServices";
import AddOrdenes from "./AddOrdenes";
import { database } from "../../firebase";
import { ref, onValue, query, orderByChild, limitToLast, equalTo, set } from "firebase/database";
import { getFirestore, collection, query as queryFirestore, getDocs, orderBy, limit, setDoc, doc, where, addDoc } from "firebase/firestore";
import ProductosDataService from "../../services/ordenes.service";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { Autocomplete, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, OutlinedInput, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import Paper from '@mui/material/Paper';
import moment from "moment";
const firestore = getFirestore();

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function OTWrapper() {
    const [actualStep, setActualStep] = useState('order');
    let [dataToSave, setDataToSave] = useState([])
    const [snackbarInfo, setSnackbarInfo] = useState({ text: '', type: '', open: false });
    const [openGarantiaModal, setOpenGarantiaModal] = useState(false)
    const [otGarantiaData, setOtGarantiaData] = useState([])
    const [checkRow, setCheckRow] = useState([])
    const [garantias, setGarantias] = useState([])
    const [services, setServices] = useState([]);
    // const [materialsData, setMaterialsData] = useState([])
    const ordenesCollection = collection(firestore, 'ordenes');

    const getServicesOnce = async () => {
        try {
            const servicesCollection = collection(firestore, 'servicios');
            const queryServices = queryFirestore(servicesCollection, orderBy('id', 'asc'));
            const snapshot = await getDocs(queryServices);
            const data = snapshot.docs.map(doc => doc.data());
            setServices(data);
        } catch (error) {
            console.error("Error al obtener los datos de servicios:", error);
        }
    }

    useEffect(() => {
        getServicesOnce()
    }, []);

    const goToService = (data) => {
        setDataToSave(prevState => ({ ...prevState, ...data }));
        setActualStep('service')
    }

    const goToMaterials = (services) => {
        setDataToSave(prevState => ({ ...prevState, ...services }));
        // setDataToSave(services);
        setActualStep('material')
    }

    const goToOrder = () => {
        setActualStep('order')
    }

    const validateFormulario = async (newData) => {
        const dateOt = moment(newData?.date);
        const dateFrom = moment(dateOt).subtract(30, 'days').format("YYYYMMDD");
        const dateTo = moment(dateOt).add(30, 'days').format("YYYYMMDD");
        const searchQuery = queryFirestore(ordenesCollection, orderBy('contrato', 'asc'), where('contrato', '==', newData?.contrato));
        const querySnapshot = await getDocs(searchQuery);

        if (!querySnapshot.empty) {
            const data = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
            const registrosFiltrados = data.filter(registro => {
                const date = registro.date;
                return date >= dateFrom && date <= dateTo;
            });
            if (registrosFiltrados.length > 0) {
                const arrayRegistros = registrosFiltrados;
                setActualStep('finish')
                setOtGarantiaData(arrayRegistros)
                setOpenGarantiaModal(true)
            } else {
                setOpenGarantiaModal(false)
                setActualStep('finish')
            }
        } else {
            setOpenGarantiaModal(false)
            setActualStep('finish')
        }

        // const searchQuery = query(ref(database, 'ordenes'), orderByChild('contrato'), equalTo(newData?.contrato));
        // onValue(searchQuery, (snapshot) => {
        //     if (snapshot.val()) {
        //         const registrosFiltrados = Object.values(snapshot.val()).filter(registro => {
        //             const date = registro.date;
        //             return date >= dateFrom && date <= dateTo;
        //         });
        //         if (registrosFiltrados.length > 0) {
        //             const arrayRegistros = registrosFiltrados;
        //             setActualStep('finish')
        //             setOtGarantiaData(arrayRegistros)
        //             setOpenGarantiaModal(true)
        //         } else {
        //             setOpenGarantiaModal(false)
        //             setActualStep('finish')
        //         }
        //     } else {
        //         setOpenGarantiaModal(false)
        //         setActualStep('finish')
        //     }
        // }, {
        //     onlyOnce: true
        // });
    }

    const saveOnlyOrder = async (materialsData) => {
        const newArray = dataToSave
        const dateHour = moment(new Date().getTime()).format("DD/MM/YYYY HH:mm")
        newArray.confectionData = dateHour;
        const newDataOrderToSave = { ...newArray, ...materialsData }

        const firestore = getFirestore();

        try {
            // Consulta para obtener el documento con el ID más alto
            const q = queryFirestore(ordenesCollection, orderBy("id", "desc"), limit(1));
            const querySnapshot = await getDocs(q);

            let lastId = 0;

            // Si existe al menos un documento, obtener el último ID
            if (!querySnapshot.empty) {
                querySnapshot.forEach((doc) => {
                    lastId = doc.data().id;
                });
            }

            // Generar el nuevo ID sumando 1 al último ID
            const newId = lastId + 1;

            // Nuevo registro con el nuevo ID
            newDataOrderToSave.id = newId;

            // Guardar el nuevo documento en Firestore con el nuevo ID
            await setDoc(doc(firestore, "ordenes", newId.toString()), newDataOrderToSave);

            console.log("Nuevo documento guardado con éxito");
            validateFormulario(newDataOrderToSave);
            // setSpinner(false);
            // setSaveOk(newId)
            // setTimeout(() => {
            //     window.location.href = `/imprimir/${dataRemitoToSave?.newId}`
            // }, 5000);

        } catch (error) {
            console.error("Error al guardar el nuevo documento: ", error);
        }
    };

    const onSave = (materialsDate) => {
        // let lastId = -1
        // const topUserPostsRef = query(ref(database, 'ordenes'), orderByChild('id'), limitToLast(1));
        // onValue(topUserPostsRef, (snapshot) => {
        //     const key = snapshot.val() ? Object.keys(snapshot.val()) : -1;
        //     lastId = parseInt(key, 10);
        //     saveOt(lastId, materialsDate)
        // }, {
        //     onlyOnce: true
        // });
        saveOnlyOrder(materialsDate)
    }

    const saveOt = (lastId, materialsData) => {
        const newArray = dataToSave
        const dateHour = moment(new Date().getTime()).format("DD/MM/YYYY HH:mm")
        const newId = lastId + 1;
        newArray.id = newId;
        newArray.confectionData = dateHour;
        const newData = { ...newArray, ...materialsData }
        ProductosDataService.create(newData, newId)
            .then(() => {
                validateFormulario(newData);
                // setTimeout(() => {
                //         setActualStep('finish')
                //     }, 1000);
            })
            .catch((e) => {
                setSnackbarInfo({ text: 'Ocurrió un error al guardar la OT, intente nuevamente', type: 'error', open: true })
            });
    }

    const handleCloseSanckbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarInfo({ text: '', type: 'info', open: false });
    };

    const handleCloseModal = () => {
        setOpenGarantiaModal(false);
    };

    const formatDataToSaveGarantia = (row, index) => {
        const dataRowToSave = {
            idOt: row.id,
            cliente: row.cliente,
            comments: row.comments,
            contrato: row.contrato,
            cuadrilla: row.cuadrilla,
            date: row.date,
            nroFormulario: row.nroFormulario,
            nroOt: row.nroOt,
            services: row.services,
            indexRow: index,
            tipoCliente: row.tipoCliente,
            tipoDia: row.tipoDia,
        };
        return dataRowToSave;
    }

    const changeCheckGarantia = (index, row) => {
        const dataRowToSave = formatDataToSaveGarantia(row, index);
        if (checkRow?.includes(index)) {
            setCheckRow(checkRow.filter(item => item !== index));
            const indexDelete = garantias.findIndex((obj) => obj.indexRow === index)
            garantias.splice(indexDelete, 1);
            setGarantias(prevState => ([...prevState]));
        } else {
            setCheckRow([...checkRow, index]);
            const newArray = [...garantias, dataRowToSave]
            setGarantias(newArray)
        }
    }

    const onChangeSelectGarantia = (e, row, index) => {
        e.preventDefault();
        const dataRowToSave = formatDataToSaveGarantia(row, index);
        const indexSave = garantias.findIndex((obj) => obj.indexRow === index)
        garantias[indexSave].otRelacionada = dataRowToSave;
        setGarantias(prevState => ([...prevState]))
    }

    const saveGarantias = async () => {
        if (garantias?.length > 0) {

            try {
                // Paso 1: Obtener el último ID
                const q = query(collection(firestore, "garantias"), orderBy("id", "desc"), limit(1));
                const querySnapshot = await getDocs(q);

                let lastId = 0;

                // Si existe al menos un documento, obtener el último ID
                if (!querySnapshot.empty) {
                    lastId = querySnapshot.docs[0].data().id;
                }

                // Paso 2: Generar el nuevo ID
                const newId = lastId + 1;

                // Paso 3: Crear el nuevo objeto de garantía
                const newGarantia = {
                    contrato: garantias[0]?.contrato,
                    id: newId,
                    date: garantias[0]?.date,
                    ...garantias,
                };
                console.log("newGarantia", newGarantia, garantias[0]);


                // Paso 4: Guardar el nuevo registro en Firestore con el nuevo ID
                await setDoc(doc(firestore, "garantias", newId.toString()), newGarantia);

                console.log("Garantía guardada correctamente");
                setActualStep("finishGarantia");
                setOpenGarantiaModal(false);

            } catch (error) {
                console.error("Error al guardar la garantía:", error);
                setSnackbarInfo({ text: "Ocurrió un error al guardar las garantías", type: "error", open: true });
            }
        }
    };

    // const onSaveGarantia = (lastId) => {
    //     const newArray = garantias
    //     const newId = lastId + 1;
    //     newArray.contrato = garantias[0]?.contrato;
    //     newArray.id = newId;
    //     newArray.date = garantias[0]?.date;
    //     set(ref(database, 'garantias/' + newId), newArray)
    //         .then(() => {
    //             console.log('ok garantias')
    //             setActualStep('finishGarantia')
    //             setOpenGarantiaModal(false);
    //         })
    //         .catch(() => setSnackbarInfo({ text: 'Ocurrió un error al guardar las garantias', type: 'error', open: true }))
    // }

    // const saveGarantias = () => {
    //     if (garantias?.length > 0) {
    //         let lastiIdGarantia = -1
    //         const topUserPostsRef = query(ref(database, 'garantias'), orderByChild('id'), limitToLast(1));
    //         onValue(topUserPostsRef, (snapshot) => {
    //             const key = snapshot.val() ? Object.keys(snapshot.val()) : -1;
    //             lastiIdGarantia = parseInt(key, 10);
    //             onSaveGarantia(lastiIdGarantia)
    //         }, {
    //             onlyOnce: true
    //         });
    //     }
    // }

    return (
        <>
            {actualStep === 'order' &&
                <AddOrdenes nextStep={goToService} />
            }
            {actualStep === 'service' &&
                <AddServices nextStep={goToMaterials} goToOrder={goToOrder} data={dataToSave} servicesData={services} />
            }
            {actualStep === 'material' &&
                <AddMaterials nextStep={goToService} data={dataToSave} onSave={onSave} />
            }
            {actualStep === 'finish' &&
                <div>
                    <Typography variant="h4">Guardado correctamente!</Typography>
                    <Button variant="contained" color="primary" href="/ordenes">Nueva OT</Button>
                    <Button href="/list-ordenes">
                        Ir a listado
                    </Button>
                </div>
            }
            {actualStep === 'finishGarantia' &&
                <div>
                    <Typography variant="h4">Garantia guardada correctamente!</Typography>
                    <Button variant="contained" color="primary" href="/ordenes">Nueva OT</Button>
                    <Button href="/list-ordenes">
                        Ir a listado
                    </Button>
                </div>
            }
            <Snackbar open={snackbarInfo.open} autoHideDuration={2000} onClose={handleCloseSanckbar} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert onClose={handleCloseSanckbar} severity={snackbarInfo.type} sx={{ width: '100%' }}>
                    {snackbarInfo.text}
                </Alert>
            </Snackbar>
            {openGarantiaModal &&
                <Dialog
                    open={openGarantiaModal}
                    onClose={handleCloseModal}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth="xl"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Asignación de garantías"}
                    </DialogTitle>
                    <DialogContent>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Nro Formulario</TableCell>
                                        <TableCell>Nro OT</TableCell>
                                        <TableCell>Contrato</TableCell>
                                        <TableCell>Cliente</TableCell>
                                        <TableCell>Fecha</TableCell>
                                        <TableCell>Comentarios</TableCell>
                                        <TableCell>Es garantía?</TableCell>
                                        <TableCell>Garantía de</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {otGarantiaData.map((row, index) => (
                                        <TableRow
                                            key={row.id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell>{row.nroFormulario}</TableCell>
                                            <TableCell>{row.nroOt}</TableCell>
                                            <TableCell>{row.contrato}</TableCell>
                                            <TableCell>{row.cliente}</TableCell>
                                            <TableCell>{moment(row.date).format("DD/MM/YYYY")}</TableCell>
                                            <TableCell>{row.comments}</TableCell>
                                            <TableCell>
                                                <Checkbox
                                                    onChange={() => changeCheckGarantia(index, row)}
                                                    checked={checkRow?.includes(index)}
                                                />
                                            </TableCell>
                                            <TableCell sx={{ minWidth: '160px' }}>
                                                <FormControl sx={{ width: '100%', margin: '10px 0' }}>
                                                    <Autocomplete
                                                        options={otGarantiaData}
                                                        id="disable-clearable"
                                                        disableClearable
                                                        onChange={(e, ot) => onChangeSelectGarantia(e, ot, index)}
                                                        getOptionLabel={(option) => option.nroOt}
                                                        fullWidth
                                                        disabled={!checkRow?.includes(index)}
                                                        renderInput={(params) => (
                                                            <TextField {...params} label="Nro OT relacionada" placeholder="Nro OT relacionada" />
                                                        )}
                                                    />
                                                </FormControl>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseModal}>Cerrar</Button>
                        <Button onClick={saveGarantias} autoFocus>
                            Guardar
                        </Button>
                    </DialogActions>
                </Dialog>
            }
        </>
    )

}

export default OTWrapper;
